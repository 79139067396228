import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { BsArrowRight } from "react-icons/bs"
import "../components/css/usecase.scss"
import { Helmet } from "react-helmet"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Usecasetabs from "../components/Usecasetabs"
import ForCampus_Network from "../images/usecase/ForCampus_Network.jpg"
import ExplodedView_Indoor_Small from "../images/usecase/ExplodedView_Indoor_Small.png"
import Indoor_Warehouse from "../images/usecase/Indoor_Warehouse.png"
import For_Fixed_Wireless from "../images/usecase/For_Fixed_Wireless.jpg"
import Lid_Off_Outdoor_Small_Cell from "../images/usecase/Lid_Off_Outdoor_Small_Cell.png"
import ForTelcoOutdoor from "../images/usecase/ForTelcoOutdoor.jpg"
import openranmarco_img from "../images/usecase/openranmarco_img.png"
import Acceleration_Card from "../images/usecase/Acceleration_Card.png"
import Satellites_main_img from "../images/usecase/Satellites_main_img.png"
import Satellites_imgs from "../images/usecase/Satellites_imgs.png"


const usecase = ({ location }) => {
  const companylist = [
    {
      h3Text: "Antenna",
      para: (
        <>2T2R, 4T4R, 8T8R <br/> Sub-6 bands, mmWave</>
      ),
    },
    {
      h3Text: "Performance",
      para: "Up to 5.0 Gbps user rate",
    },
    {
      h3Text: "Timing Sync",
      para: "1588V2, GPS, NDT",
    },
    {
      h3Text: "RF Output Power",
      para: "Up to 24 dBm per tx chain",
    },
    {
      h3Text: "EdgeQ Differentiators",
      para: (
        <>Integrated CPU + NPU <br/> Dual Mode LTE + 5G</>
      )
    },
  ]

  const Industriallist = [
    {
      h3Text: "Antenna",
      para: (
        <>2T2R, 4T4R <br/> Sub-6 bands, mmWave</>
      )
    },
    {
      h3Text: "Performance",
      para: "Up to 5.0 Gbps at 256 users",
    },
    {
      h3Text: "Timing Sync",
      para: "1588V2, GPS, SyncE",
    },
    {
      h3Text: "RF Output Power",
      para: "Up to 35 dBm per tx chain",
    },
    {
      h3Text: "EdgeQ Differentiators",
      para: (
        <>URLLC (99.9999%, 1 ms) <br/>Integrated CPU + NPU</>
      )
    },
  ]  

  const Wirelesslist = [
    {
      h3Text: "Antenna",
      para: (
        <>2T2R, 4T4R <br/> Sub-6 bands, mmWave</>
      )
    },
    {
      h3Text: "Performance",
      para: "Up to 5.0 Gbps at 64 users",
    },
    {
      h3Text: "Timing Sync",
      para: "1588V2, GPS, NDT",
    },
    {
      h3Text: "RF Output Power",
      para: "Up to 33 dBm per tx chain",
    },
    {
      h3Text: "EdgeQ Differentiators",
      para: "Integrated CPU + NPU",
    },
  ]  

  const outdoorlist = [
    {
      h3Text: "Antenna",
      para: (
        <>2T2R, 4T4R <br/> Sub-6 bands, mmWave</>
      )      
    },
    {
      h3Text: "Performance",
      para: "Up to 5.0 Gbps at 256 users",
    },
    {
      h3Text: "Timing Sync",
      para: "1588V2, GPS, SyncE",
    },
    {
      h3Text: "RF Output Power",
      para: "Up to 40 dBm per tx chain",
    },
    {
      h3Text: "EdgeQ Differentiators",
      para: (
        <>Integrated CPU + NPU <br/> Dual Mode LTE + 5G</>
      )  
    },
  ]

  const openranmarco = [
    {
      h3Text: "Massive MIMO",
      para: "Up to 64TRX",
    },
    {
      h3Text: "ORAN Interface",
      para: (
        <>Fronthaul: split option 7.2 over eCPRI <br/> L1-L2: split option 6, FAPI over PCIe</>
      )
    },
    {
      h3Text: "User Capacity",
      para: "10,000 users per card",
    },
    {
      h3Text: "Timing Sync",
      para: "1588V2, GPS, SyncE",
    },
    {
      h3Text: "Synchronization Topology",
      para: "LLS-C1, LLS-C2, LLS-C3 ",
    },
    {
      h3Text: "EdgeQ Differentiators",
      para: (
        <>Dual Mode LTE + 5G <br/> Integrated timing sync <br/> {"<50W card power"} </> 
      )  
    },
  ]

  const Satellites = [
    {
      h3Text: "Frequency",
      para: "L, S, C-Bands & Ka, Ku-Bands",
    },
    {
      h3Text: "Satellite Payload",
      para: (
        <>RU/DU Regenerative Payload <br/> NTN Gateway</>
      ) 
    },
    {
      h3Text: "Massive MIMO",
      para: (
        <>32T32R / 64T64R Phased Array <br/> Beam hopping</>
      ) 
    },
    {
      h3Text: "SW Defined Radio",
      para: (
        <>5G NR NTN <br/> Large number of carriers for IOT <br/> Waveform customization <br/> non-5G NR modulations</>
      ) 
    },
    {
      h3Text: "EdgeQ Differentiators",
      para: "Custom PHY extensions",
    },
  ]

  return (
    <Layout>
      <Seo title="EdgeQ | Use Cases" />
      <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Home - EdgeQ" />
        <meta
          property="og:description"
          content="5G Base Station on-a-Chip 5G + AI | RISC-V Architecture | Open &amp; Programmable Learn More EdgeQ Samples World’s First Software Defined 5G Base Station-on-a-Chip to Market Leaders in Enterprise, Telco and Hyperscale Cloud Read More WATCHNEW VIDEO SERIES technology EdgeQ Technology We empower companies with an open and adaptable 5G+AI platform, hardware that is &hellip; Home Read More &raquo;"
        />
        <meta property="og:url" content="https://edgeq.io/" />
        <meta property="og:site_name" content="EdgeQ" />
        <meta
          property="article:modified_time"
          content="2021-10-26T15:39:25+00:00"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content="6 minutes" />
      </Helmet>
      <main className="usecasePage">
        <section className="media-first-sec">
          <Container>
            <Row>
              <Col md={12}>
                <div className="media-heading">
                  <h2 data-aos="fade-down">
                    redefining wireless <br />
                    <span className="orange">infrastructure</span>
                  </h2>
                </div>
              </Col>
            </Row>
            <section className="press-our-sec">
              <div className="press-buttons">
                <a href="#smallcell" class="ExpertisesNav-item">
                  <div class="ExpertisesNav-itemInner">
                    <div class="ExpertisesNav-itemLabel">
                    SMALL CELL
                    </div>
                    <span className="arowup ExpertisesNav-itemArrow icon sprite-icons">
                      <BsArrowRight />
                    </span>
                    <div class="ExpertisesNav-itemColorWrap">
                      <div class="ExpertisesNav-itemColor"></div>
                    </div>
                  </div>
                </a>
                <a href="#openranmacrocell" class="ExpertisesNav-item">
                  <div class="ExpertisesNav-itemInner">
                    <div class="ExpertisesNav-itemLabel">
                    Open RAN  <br /> Macro Cell
                    </div>
                    <span className="arowup ExpertisesNav-itemArrow icon sprite-icons">
                      <BsArrowRight />
                    </span>
                    <div class="ExpertisesNav-itemColorWrap">
                      <div class="ExpertisesNav-itemColor"></div>
                    </div>
                  </div>
                </a>
                <a href="#satellite" class="ExpertisesNav-item">
                  <div class="ExpertisesNav-itemInner">
                    <div class="ExpertisesNav-itemLabel">
                    Satellite  <br /> Communication
                    </div>
                    <span className="arowup ExpertisesNav-itemArrow icon sprite-icons">
                      <BsArrowRight />
                    </span>
                    <div class="ExpertisesNav-itemColorWrap">
                      <div class="ExpertisesNav-itemColor"></div>
                    </div>
                  </div>
                </a>
              </div>
            </section>
          </Container>
        </section>

        <section className="media-second-sec" id="smallcell">
          <Container>
            <Row>
              <Col md={12}>
                <div className="Media-main-heading">
                  <h3>Small Cell</h3>
                </div>
              </Col>
            </Row>
          </Container>

          <div className="usecase_tabs">
            <Tabs>
              <Container>
                <TabList>
                  <Tab>Campus Network</Tab>
                  <Tab>Industrial</Tab>
                  <Tab>Fixed Wireless</Tab>
                  <Tab>Telco Outdoor</Tab>
                </TabList>
              </Container>

              <TabPanel>
                <Usecasetabs
                  mainimage={ForCampus_Network}
                  mainimagealt={"ForCampus_Network"}
                  leftHeadeing={"Campus Network"}
                  leftSmallHeading={
                    "Dual Mode 5G + LTE Private Cellular Network"
                  }
                  leftimage={ExplodedView_Indoor_Small}
                  leftimageAlt={"ExplodedView_Indoor_Small"}
                  tablist={companylist}
                />
              </TabPanel>
              <TabPanel>
                <Usecasetabs
                  mainimage={Indoor_Warehouse}
                  mainimagealt={"Indoor_Warehouse"}
                  leftHeadeing={"Industrial"}
                  leftSmallHeading={"5G Industrial Private Network"}
                  leftimage={ExplodedView_Indoor_Small}
                  leftimageAlt={"ExplodedView_Indoor_Small"}
                  tablist={Industriallist}
                />
              </TabPanel>
              <TabPanel>
                <Usecasetabs
                  mainimage={For_Fixed_Wireless}
                  mainimagealt={"For_Fixed_Wireless"}
                  leftHeadeing={"Fixed Wireless"}
                  leftSmallHeading={"5G Wireless Broadband Internet"}
                  leftimage={Lid_Off_Outdoor_Small_Cell}
                  leftimageAlt={"Lid_Off_Outdoor_Small_Cell"}
                  tablist={Wirelesslist}
                />
              </TabPanel>
              <TabPanel>
              <Usecasetabs
                  mainimage={ForTelcoOutdoor}
                  mainimagealt={"ForTelcoOutdoor"}
                  leftHeadeing={"telco outdoor small cell"}
                  leftSmallHeading={"5G + LTE Public Cellular Network"}
                  leftimage={ExplodedView_Indoor_Small}
                  leftimageAlt={"ExplodedView_Indoor_Small"}
                  tablist={outdoorlist}
                />
              </TabPanel>
            </Tabs>
          </div>
        </section>

        <section className="media-second-sec" id="openranmacrocell">
          <Container>
            <Row>
              <Col md={12}>
                <div className="Media-main-heading">
                  <h3>Open RAN Macro Cell</h3>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="usecase_main">
            <Usecasetabs
              mainimage={openranmarco_img}
              mainimagealt={"openranmarco_img"}
              leftHeadeing={"Base Stations"}
              leftSmallHeading={"5G + LTE MaMIMO Macrocell"}
              leftimage={Acceleration_Card}
              leftimageAlt={"Acceleration_Card"}
              tablist={openranmarco}
            />
          </div>
        </section>

        <section className="media-second-sec" id="satellite">
          <Container>
            <Row>
              <Col md={12}>
                <div className="Media-main-heading">
                  <h3>Satellite Communication</h3>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="usecase_main">
            <Usecasetabs
              mainimage={Satellites_main_img}
              mainimagealt={"Satellites_main_img"}
              leftHeadeing={"Satellites"}
              leftSmallHeading={"5G Non-Terrestrial Networks for Mobile Broadband"}
              leftimage={Satellites_imgs}
              leftimageAlt={"Satellites_imgs"}
              tablist={Satellites}
            />
          </div>
        </section>
      </main>
    </Layout>
  )
}
export default usecase
