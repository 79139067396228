import * as React from "react"
import { Row, Col } from "react-bootstrap"

function Usecasetabs(props) {
  const {
    mainimage,
    mainimagealt,
    leftHeadeing,
    leftSmallHeading,
    leftimage,
    leftimageAlt,
    tablist,
    tabsdata,
  } = props

  return (
    <section className="Usecasetabs_sec">
      <div className="Usecasetabs_sec_img">
        <img src={mainimage} alt={mainimagealt} />
      </div>
      <div className="Usecasetabs_sec_detail">
        <Row>
          <Col md={5}>
            <div className="tabsleft">
              {leftHeadeing && (
                <h2>
                  For <span>{leftHeadeing}</span>
                </h2>
              )}

              {leftSmallHeading && <h3>{leftSmallHeading}</h3>}

              <img src={leftimage} alt={leftimageAlt} />
            </div>
          </Col>
          <Col md={7}>
            <div className="tabsright">
              <h2>
                <svg
                  width="50"
                  height="24"
                  viewBox="0 0 50 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M49.5607 13.0607C50.1464 12.4749 50.1464 11.5251 49.5607 10.9393L40.0147 1.3934C39.4289 0.807611 38.4792 0.807611 37.8934 1.3934C37.3076 1.97919 37.3076 2.92893 37.8934 3.51472L46.3787 12L37.8934 20.4853C37.3076 21.0711 37.3076 22.0208 37.8934 22.6066C38.4792 23.1924 39.4289 23.1924 40.0147 22.6066L49.5607 13.0607ZM0 13.5H48.5V10.5H0V13.5Z"
                    fill="black"
                  />
                </svg>
                Specs
              </h2>

                {tablist && (
                  <div className="speclisting">
                    <ul >
                    {tablist.map((list, index) => {
                      return (
                        
                          <li key={index}>
                          <h3><span>{list.h3Text}</span></h3>
                          <p>{list.para}</p>
                          </li>
                        
                      )
                    })}
                    </ul>
                  </div>
                )}
              </div>

          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Usecasetabs
